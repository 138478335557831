var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reset-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "card-box login-form",
          attrs: {
            "auto-complete": "on",
            model: _vm.form,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "0px",
            disabled: _vm.loading,
          },
        },
        [
          _c("div", { staticClass: "login-info" }, [
            _vm._v("Please set a new password to continue."),
          ]),
          _c(
            "div",
            { staticClass: "el-form-group" },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c("el-input", {
                    attrs: {
                      name: "password",
                      type: _vm.pwdType,
                      "auto-complete": "off",
                      placeholder: "New password",
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                  _c(
                    "span",
                    { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                    [_c("svgicon", { attrs: { name: "eye" } })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "password2" } },
                [
                  _c("el-input", {
                    attrs: {
                      name: "password2",
                      type: _vm.pwdType,
                      "auto-complete": "off",
                      placeholder: "Re-enter password",
                    },
                    model: {
                      value: _vm.form.password2,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password2", $$v)
                      },
                      expression: "form.password2",
                    },
                  }),
                  _c(
                    "span",
                    { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                    [_c("svgicon", { attrs: { name: "eye" } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%" },
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" Save ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }