<template>
    <div class="reset-container">
        <el-form
            ref="form"
            auto-complete="on"
            :model="form"
            :rules="rules"
            label-position="left"
            label-width="0px"
            class="card-box login-form"
            :disabled="loading"
        >
            <div class="login-info">Please set a new password to continue.</div>
            <div class="el-form-group">
                <el-form-item prop="password">
                    <el-input
                        v-model="form.password"
                        name="password"
                        :type="pwdType"
                        auto-complete="off"
                        placeholder="New password"
                    />
                    <span class="show-pwd" @click="showPwd">
                        <svgicon name="eye" />
                    </span>
                </el-form-item>
                <el-form-item prop="password2">
                    <el-input
                        v-model="form.password2"
                        name="password2"
                        :type="pwdType"
                        auto-complete="off"
                        placeholder="Re-enter password"
                    />
                    <span class="show-pwd" @click="showPwd">
                        <svgicon name="eye" />
                    </span>
                </el-form-item>
            </div>
            <el-button
                type="primary"
                style="width: 100%"
                :loading="loading"
                @click="handleSubmit"
            >
                Save
            </el-button>
        </el-form>
    </div>
</template>

<script>
import {isValidEmail, validateNDISNumber} from '@/utils/validate';
import logo from '@/assets/logo.svg';
import firebase from 'firebase/app';
import auth from '@/utils/auth';

export default {
    name: 'reset',
    data() {
        const validatePass = (rule, value, callback) => {
            if (value.length < 6) {
                callback(new Error('Invalid Password'));
            } else if (this.form.password2 && value !== this.form.password2) {
                callback(new Error('Passwords do not match'));
            } else {
                callback();
            }
        };
        const validatePass2 = (rule, value, callback) => {
            if (value !== this.form.password) {
                callback(new Error('Passwords do not match'));
            } else {
                callback();
            }
        };
        return {
            logo,
            form: {
                password: '',
                password2: '',
            },
            rules: {
                password: [
                    {required: true, trigger: 'blur', validator: validatePass},
                ],
                password2: [
                    {required: true, trigger: 'blur', validator: validatePass2},
                ],
            },
            loading: false,
            pwdType: 'password',
        };
    },
    methods: {
        showPwd() {
            if (this.pwdType === 'password') {
                this.pwdType = '';
            } else {
                this.pwdType = 'password';
            }
        },
        validateForm() {
            // turn the validation callback into a promise
            return new Promise((resolve, reject) => {
                console.log('Validate...');
                this.$refs.form.validate((valid) => {
                    resolve(valid);
                });
            });
        },
        async handleSubmit() {
            const valid = await this.validateForm();

            if (valid) {
                this.loading = true;
                try {
                    // update firebase user auth
                    await auth.user.updatePassword(this.form.password);
                    // update user record
                    await this.$fire.doc(`users/${auth.user.uid}`).update({
                        is_temporary_password: false,
                    });

                    auth.userData.is_temporary_password = false;
                    this.$bus.$emit('refreshAuth', {});
                    this.$router.push('/').catch((e) => {});
                } catch (error) {
                    this.loading = false;
                    this.$notify.error({
                        title: 'Error',
                        message: error.message,
                    });
                }
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.reset-container {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background-color: $grey;

    .svg-container {
        padding: 6px 5px 6px 15px;
        vertical-align: middle;
        width: 30px;
        display: inline-block;

        &_login {
            font-size: 20px;
        }
    }

    .login-form {
        margin-top: 200px;
        position: relative;
        border-radius: 10px;
        width: 500px;
        height: 320px;
        padding: 0 35px;
        overflow: hidden;
        background-color: white;
        border: 1px solid #dcdfe6;

        .login-info {
            text-align: center;
            font-size: 14px;
            padding-top: 20px;
        }

        .logo {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .el-form-group {
            padding: 40px 0;

            .el-form-item {
                border: 1px solid rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                background: white;
                color: #000000;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .el-form-item__content {
                    display: flex;

                    .el-input {
                        height: 52px;
                        width: 85%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        input:-webkit-autofill {
                            -webkit-box-shadow: 0 0 0px 1000px white inset !important;
                            -webkit-text-fill-color: #000000 !important;
                        }

                        input {
                            background: transparent;
                            border: 0px;
                            -webkit-appearance: none;
                            border-radius: 0px;
                            color: #000000;
                            height: 47px;
                        }
                    }

                    .show-pwd {
                        position: absolute;
                        right: 10px;
                        top: 7px;
                        font-size: 16px;
                        color: #000000;
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }
        }
    }

    .thirdparty-button {
        position: absolute;
        right: 35px;
        bottom: 28px;
    }
}
</style>
